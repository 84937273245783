exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-du-an-thiet-ke-thi-cong-[item]-tsx": () => import("./../../../src/pages/du-an-thiet-ke-thi-cong/[item].tsx" /* webpackChunkName: "component---src-pages-du-an-thiet-ke-thi-cong-[item]-tsx" */),
  "component---src-pages-du-an-thiet-ke-thi-cong-index-tsx": () => import("./../../../src/pages/du-an-thiet-ke-thi-cong/index.tsx" /* webpackChunkName: "component---src-pages-du-an-thiet-ke-thi-cong-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lien-he-index-tsx": () => import("./../../../src/pages/lien-he/index.tsx" /* webpackChunkName: "component---src-pages-lien-he-index-tsx" */),
  "component---src-pages-thiet-ke-va-thi-cong-[item]-tsx": () => import("./../../../src/pages/thiet-ke-va-thi-cong/[item].tsx" /* webpackChunkName: "component---src-pages-thiet-ke-va-thi-cong-[item]-tsx" */),
  "component---src-pages-thiet-ke-va-thi-cong-index-tsx": () => import("./../../../src/pages/thiet-ke-va-thi-cong/index.tsx" /* webpackChunkName: "component---src-pages-thiet-ke-va-thi-cong-index-tsx" */),
  "component---src-pages-tin-tuc-[alias]-tsx": () => import("./../../../src/pages/tin-tuc/[alias].tsx" /* webpackChunkName: "component---src-pages-tin-tuc-[alias]-tsx" */),
  "component---src-pages-tin-tuc-index-tsx": () => import("./../../../src/pages/tin-tuc/index.tsx" /* webpackChunkName: "component---src-pages-tin-tuc-index-tsx" */),
  "component---src-pages-trang-chu-blog-article-cards-tsx": () => import("./../../../src/pages/trang-chu/BlogArticleCards.tsx" /* webpackChunkName: "component---src-pages-trang-chu-blog-article-cards-tsx" */),
  "component---src-pages-trang-chu-commit-card-tsx": () => import("./../../../src/pages/trang-chu/CommitCard.tsx" /* webpackChunkName: "component---src-pages-trang-chu-commit-card-tsx" */),
  "component---src-pages-trang-chu-index-tsx": () => import("./../../../src/pages/trang-chu/index.tsx" /* webpackChunkName: "component---src-pages-trang-chu-index-tsx" */),
  "component---src-pages-trang-chu-project-article-cards-tsx": () => import("./../../../src/pages/trang-chu/ProjectArticleCards.tsx" /* webpackChunkName: "component---src-pages-trang-chu-project-article-cards-tsx" */),
  "component---src-pages-ve-chung-toi-index-tsx": () => import("./../../../src/pages/ve-chung-toi/index.tsx" /* webpackChunkName: "component---src-pages-ve-chung-toi-index-tsx" */)
}

