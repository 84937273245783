// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import "@mantine/core/styles.css";
import React from "react";
import { MantineProvider } from "@mantine/core";
import { theme } from "./src/theme";
import "./src/styles/global.css";
import "@mantine/carousel/styles.css";
import "@mantine/notifications/styles.css";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "react-query";

export const wrapPageElement = ({ element }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <Notifications />
        {element}
      </MantineProvider>
    </QueryClientProvider>
  );
};
